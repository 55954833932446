
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  name: 'IconCarouselElement',
  components: {
    BaseHeadline: () => import('../base/BaseHeadline.vue'),
    BaseIcon: () => import('../base/BaseIcon.vue'),
  },
})
export default class IconCarouselElement extends Vue {
  @Prop() iconName ?: string

  @Prop() headline ?: string

  @Prop() text ?: string
}
